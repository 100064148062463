<template lang="html">
  <nav>
    <div class="links">
        <router-link class="" :to="{ name: 'home' }">Sung Tieu</router-link>
        <router-link class="" :to="{ name: 'info' }">Information</router-link>
        <span class="news" v-if="current" v-html="current"></span>
    </div>
  </nav>
</template>

<script>
export default {
  computed: {
    current() {
      return this.$store.state.site.current;
    },
  },
  methods: {
    toggleSettings() {
      window.scrollTo(0, 0);
      this.$store.state.settings = !this.$store.state.settings;
    },
  },
};
</script>

<style lang="scss" scoped>
nav {
    width: 100%;
    box-sizing: border-box;
    display: grid;
    position:fixed;
    background:linear-gradient(0deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 25%);
    top:0;
    left:0;
    z-index: 90;
    width:100%;
    grid-template-columns: repeat(6, 1fr);
    grid-gap: calc(4 * var(--lh));
    padding: calc(1 * var(--lh));
    @media only screen and (max-width:768px) {
        padding-bottom: calc(2*var(--lh));
    }
  a{
    margin-right: calc(2 * var(--lh));
    @media only screen and (max-width: 768px) {
      margin-right:0;
    }
  }
  .links {
    grid-column: span 6;
    display: flex;
    @media only screen and (max-width: 768px) {
      flex-wrap:wrap;
    }
  }
  .news{
    text-align:right;
    flex:1 0 auto;
    @media only screen and (max-width: 768px) {
      flex: 0 0 auto;
      margin-top:var(--lh);
      text-align:left;
      width:100%;
    }
  }
  .settings-link {
    grid-column: span 1;
    margin-left: auto;
    transition: color 0.5s;
    &:hover {
      cursor: pointer;
    //   color: #aaa;
    }
  }
  @media only screen and (max-width: 1024px) {
    grid-template-columns: repeat(3, 1fr);
    grid-gap: calc(1 * var(--lh));
    .links {
      grid-column: 1 / 3;
    }
    .current {
      grid-column: span 3;
    }
    .settings-link {
      grid-column: 3 / 4;
      grid-row: 1 / 1;
    }
  }
  @media only screen and (max-width: 768px) {
    .current,
    .links,
    .settings-link {
      grid-column: span 3;
      grid-row: span 1;
    }
    .settings-link {
      grid-column: 1 / 4;
      grid-row: 2 / 3;
      // margin-left:0;
    }
    a,
    span {
      padding-right: 0;
    }
    .links {
      grid-row: 1 / 2;
      display: flex;
      justify-content: space-between;
    }
  }
}
</style>
